import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../../../components/Layout';
import SEO from '../../../components/seo';
import Content from '../../../components/Content';
import Block from '../../../components/Block';
import Hero from '../../../components/Hero';
import {lang} from '../../../helpers/lang';
import SectorGridList from '../../../components/GridList';
import {getCaseStudies} from '../../../selectors/data';
import CaseStudyItem from '../../../components/CaseStudyItem';

const caseStudies = getCaseStudies();

const renderCaseStudies = (count, lang) => {
	return (
		caseStudies
			// .slice(0, count)
			.map(sector => (
				<CaseStudyItem id={sector.key} key={sector.key} lang={lang} />
			))
	);
};

const ParentCaseStudiesPage = ({pageContext}) => {
	const data = useStaticQuery(graphql`
		{
			background: file(relativePath: {eq: "pages/usecases/globe.jpg"}) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Case studies" />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<h1>Case Studies</h1>
				<p></p>
			</Hero>
			<Block light accent className="gst-block-work">
				<Content>
					<SectorGridList>{renderCaseStudies(2, lang)}</SectorGridList>
				</Content>
			</Block>
		</Layout>
	);
};

export default ParentCaseStudiesPage;
